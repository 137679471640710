import React, { useEffect, lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { loadGoogleAnalytics, logPageView } from './utils/analytics';
import BookingPage from './pages/BookingPage';
import PurchaseConfirmationPage from './pages/BookingConfirmationPage';
import Packages from './pages/PackagesPage';
import AdminPage from './pages/AdminPanel';

// Lazy loading pages for code-splitting
const SignupPage = lazy(() => import('./pages/SignupPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ForgotPage = lazy(() => import('./pages/ForgotPage'));
const ResetPage = lazy(() => import('./pages/ResetPage'));
const ResetSuccessPage = lazy(() => import('./pages/ResetSuccessPage'));
const LoaderPage = lazy(() => import('./pages/LoaderPage'));
const VerificationPage = lazy(() => import('./pages/VerificationPage'));
const VerifySuccessPage = lazy(() => import('./pages/VerifySuccessPage'));
const Home = lazy(() => import('./pages/Home'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const Discover = lazy(() => import('./pages/Discover'));
const EventDetails = lazy(() => import('./pages/EventDetails'));
const EventPage = lazy(() => import('./pages/EventPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
// const AdminEventPage = lazy(() => import('./pages/AdminEventPage'));

// Layout Component with Scroll Restoration and Page View Logging
const Layout = React.memo(() => {
  const location = useLocation();

  useEffect(() => {
    const forceScrollToTop = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };
    // Delay scroll to ensure DOM rendering is complete
    const timeout = setTimeout(forceScrollToTop, 50);
    return () => clearTimeout(timeout);
  }, [location.pathname, location.search]);

  return <Outlet />;
});

// Router Configuration
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Navigate to="/home" replace /> },
      { path: '/signup', element: <SignupPage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/forgot', element: <ForgotPage /> },
      { path: '/reset', element: <ResetPage /> },
      { path: '/reset1', element: <ResetSuccessPage /> },
      { path: '/loader', element: <LoaderPage /> },
      { path: '/verify', element: <VerificationPage /> },
      { path: '/verify1', element: <VerifySuccessPage /> },
      { path: '/privacy', element: <PrivacyPage /> },
      { path: '/terms-cond', element: <TermsPage /> },
      { path: '/home', element: <Home /> },
      { path: '/account', element: <AccountPage /> },
      { path: '/discover', element: <Discover /> },
      { path: '/packages', element: <Packages /> },
      { path: '/discover/:categoryID', element: <Discover /> },
      { path: '/purchase-confirmation', element: <PurchaseConfirmationPage /> },
      { path: '/admin', element: <AdminPage/> },
      {
        path: '/add-event',
        element: (
          <ProtectedRoute>
            <EventPage />
          </ProtectedRoute>
        ),
      },
      // {
      //   path: '/admin',
      //   element: (
      //     <ProtectedRoute>
      //       <AdminEventPage />
      //     </ProtectedRoute>
      //   ),
      // },
      { path: '/event-details/:eventName', element: <EventDetails /> },
      { path: '/purchase/:eventName', element: <BookingPage /> },
    ],
  },
]);

const App = () => {
  useEffect(() => {
    // Delay GA initialization until after critical resources are loaded
    const initializeAnalytics = () => {
      loadGoogleAnalytics()
        .then(() => {
          // console.log('Google Analytics initialized.');
          logPageView(); // Log the initial page view
        })
        .catch((error) => {
          console.error('Analytics initialization failed:', error);
        });
    };

    if ('requestIdleCallback' in window) {
      requestIdleCallback(initializeAnalytics);
    } else {
      setTimeout(initializeAnalytics, 2000); // Fallback delay
    }
  }, []);

  return (
    <Suspense fallback={<LoaderPage />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
