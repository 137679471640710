import React, { useState, useEffect, startTransition  } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/HeaderAdmin";

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [userDetails, setUserDetails] = useState(null);
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  const location = useLocation();

  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    const apiUrl = process.env.REACT_APP_API_URL;

    if (tab != null) {
      setActiveTab(tab);
    }
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails(response.data.userDetails);
            console.log(response.data.userDetails);
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };
    fetchUserDetails();
  }, []);

  const getFormattedDate = () => {
    const today = new Date();
    const options = { weekday: "short", year: "numeric", month: "long", day: "2-digit" };
    return today.toLocaleDateString("en-GB", options).replace(",", ",");
  };
  const mockData = {
    customerSpending: "120,000 RS",
    pendingPayments: "50,000 RS",
    ticketsSold: 250,
    benchmarkLimit: 300,
    dailyListings: 15,
    platformPackages: 120,
    creditPackages: 80,
    vendorPackages: 40,
    pendingApprovals: 10,
    cancellations: 5,
    stats: [
      { label: "Platinum Package", value: 236 },
      { label: "Gold Package", value: 128 },
      { label: "Silver Package", value: 28 },
      { label: "Pending Payments", value: 78 },
      { label: "Cancellations", value: 236 },
    ],
  };

  const renderContent = () => {
    if (activeTab === "dashboard") {
      return <Dashboard data={mockData} />;
    }
    return <div>Select an option from the sidebar</div>;
  };


//   return <Dashboard data={mockData} />;

  return (
    <>
      <Header searchBar={false} />
      <div className="main-body account">
        <div className="account-page-container">
          <div className="vertical-sidebar">
            <button
              className={`icon-button ${activeTab === "dashboard" ? "active" : ""}`}
              onClick={() => setActiveTab("dashboard")}
            >
              <img
                className="navigation-icon"
                src={
                  iconPath +
                  (activeTab === "dashboard"
                    ? "dashboard-active.svg"
                    : "dashboard-inactive.svg")
                }
                alt="dashboard-icon"
              />
            </button>
          </div>
          <div className="main-content">
            <div className="greeting">
              {userDetails === null || userDetails === undefined ? (
                <h6 className="heading">Welcome, Guest</h6>
              ) : (
                <h6 className="heading">Welcome, {userDetails.first_name}</h6>
              )}
              <h6 className="current-date">{getFormattedDate()}</h6>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminPage;
