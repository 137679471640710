import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  
const Dashboard = ({ data, onEdit }) => {
  const [editMode, setEditMode] = useState(false);
  const [benchmarkLimit, setBenchmarkLimit] = useState(data?.benchmarkLimit || 2500);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      setBenchmarkLimit(data.benchmarkLimit);
      setChartData({
        labels: [
          "Platform Packages",
          "Credit Packages",
          "Vendor Packages",
          "Pending Approvals",
          "Cancellations",
        ],
        datasets: [
          {
            label: "Package Stats",
            data: [
              data?.platformPackages || 10,
              data?.creditPackages || 20,
              data?.vendorPackages || 20,
              data?.pendingApprovals || 10,
              data?.cancellations || 10,
            ],
            backgroundColor: [
              "rgba(75, 192, 192, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 99, 132, 0.6)",
              "rgba(153, 102, 255, 0.6)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data]);

  const handleEdit = () => {
    // Backend logic to update benchmark limit
    onEdit?.({ benchmarkLimit });
    setEditMode(false);
  };

  const getFormattedDate = () => {
    const today = new Date();
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return today.toLocaleDateString("en-GB", options);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Stats Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div
          className="p-4 rounded-lg shadow-md"
          style={{
            background: "linear-gradient(to right, #D9E8FC, #FAF8F7)",
          }}
        >
          <h3 className="text-lg font-semibold">Total Customer Spending</h3>
          <p className="text-2xl font-bold text-blue-600">{data?.customerSpending || "Rs 0.00"}</p>
          <p className="text-sm text-gray-500">as of {getFormattedDate()}</p>
        </div>
        <div
          className="p-4 rounded-lg shadow-md"
          style={{
            background: "linear-gradient(to right, #F3F3FA, #FCF4E9)",
          }}
        >
          <h3 className="text-lg font-semibold">Pending Payments</h3>
          <p className="text-2xl font-bold text-green-600">{data?.pendingPayments || "Rs 0.00"}</p>
          <p className="text-sm text-gray-500">as of {getFormattedDate()}</p>
        </div>
        <div
          className="p-4 rounded-lg shadow-md"
          style={{
            background: "linear-gradient(to right, #FFF4F4, #FEFAEA)",
          }}
        >
          <h3 className="text-lg font-semibold">Total Ticket Benchmark Limit</h3>
          {editMode ? (
            <div>
              <input
                type="number"
                value={benchmarkLimit}
                onChange={(e) => setBenchmarkLimit(e.target.value)}
                className="border rounded p-1 text-center w-full"
              />
              <button
                onClick={handleEdit}
                className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Save
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold text-purple-600">
                {data?.ticketsSold || 0}/{benchmarkLimit}
              </p>
              <button
                onClick={() => setEditMode(true)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
      
  <div className="p-6 bg-white rounded-lg shadow-md mb-6">
    <h3 className="text-xl font-bold mb-4">Current Activity</h3>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    {/* Total Sold Tickets */}
    <div
      className="activity-card p-4 rounded-lg shadow-md text-center"
      style={{
        backgroundColor: "#D9E8FC",
        transition: "transform 0.2s ease",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
    >
      <img
        src="/icons/To_color.png"
        alt="Total Sold Tickets"
        style={{
          width: "80px",
          height: "80px",
          margin: "0 auto 16px",
          transition: "transform 0.2s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
      <h4 className="text-base font-semibold mb-2" style={{ color: "#323232" }}>
        Total Sold Tickets
      </h4>
      <p className="text-3xl font-bold" style={{ color: "#007BFF" }}>
        {data?.ticketsSold || 0}
      </p>
    </div>

    {/* Remaining Tickets */}
    <div
      className="activity-card p-4 rounded-lg shadow-md text-center"
      style={{
        backgroundColor: "#FCF3ED",
        transition: "transform 0.2s ease",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
    >
      <img
        src="/icons/2c533316230287a7fdbd7d5235c9345d.png"
        alt="Remaining Tickets"
        style={{
          width: "80px",
          height: "80px",
          margin: "0 auto 16px",
          transition: "transform 0.2s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
      <h4 className="text-base font-semibold mb-2" style={{ color: "#323232" }}>
        Remaining Tickets
      </h4>
      <p className="text-3xl font-bold" style={{ color: "#FF5733" }}>
        {data?.benchmarkLimit - data?.ticketsSold || 0}
      </p>
    </div>

    {/* Daily Listing Visitors */}
    <div
      className="activity-card p-4 rounded-lg shadow-md text-center"
      style={{
        backgroundColor: "#F2EBFB",
        transition: "transform 0.2s ease",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
    >
      <img
        src="/icons/Mobile Banking.png"
        alt="Daily Listing Visitors"
        style={{
          width: "80px",
          height: "80px",
          margin: "0 auto 16px",
          transition: "transform 0.2s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
      <h4 className="text-base font-semibold mb-2" style={{ color: "#323232" }}>
        Daily Listing Visitors
      </h4>
      <p className="text-3xl font-bold" style={{ color: "#9B59B6" }}>
        {data?.dailyListings || 0}
      </p>
    </div>
     {/* Total Views Section */}
    <div className="flex items-center mt-6 space-x-3">
    <h4 className="text-base font-semibold" style={{ color: "#323232" }}>
      Total Views
    </h4>
    <div
      className="px-3 py-1 rounded-lg text-lg font-bold"
      style={{
        backgroundColor: "#E0DFFE",
        color: "#323232",
      }}
    >
      {data?.totalViews || 0}
    </div>
    </div>
  </div>
</div>


      {/* Package Details Section with Bar Chart */}
      <div className="p-6 bg-white rounded-lg shadow-md">
  <div className="flex justify-between items-center mb-4">
    <h3 className="text-xl font-bold" style={{ color: "#323232" }}>
      Package Details
    </h3>
    <div
      style={{
        width: "32px",
        height: "32px",
        backgroundColor: "#F5F5F5",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <span
        style={{
          fontSize: "20px",
          color: "#888",
        }}
      >
        •••
      </span>
    </div>
  </div>

  {/* Bar chart (commented out) */}
  {/* {chartData ? (
    <Bar
      data={chartData}
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
        },
      }}
    />
  ) : (
    <p>Loading chart data...</p>
  )} */}

  <ul className="space-y-4 mt-4">
    <li className="flex justify-between items-center border-b pb-2" style={{ color: "#323232" }}>
      <span>Platinum Package Booked</span>
      <span className="font-bold" style={{ color: "#323232" }}>
        {data?.platformPackages || 0}
      </span>
    </li>
    <li className="flex justify-between items-center border-b pb-2" style={{ color: "#323232" }}>
      <span>Gold Package Booked</span>
      <span className="font-bold" style={{ color: "#323232" }}>
        {data?.creditPackages || 0}
      </span>
    </li>
    <li className="flex justify-between items-center border-b pb-2" style={{ color: "#323232" }}>
      <span>Silver Package Booked</span>
      <span className="font-bold" style={{ color: "#323232" }}>
        {data?.vendorPackages || 0}
      </span>
    </li>
    <li className="flex justify-between items-center border-b pb-2" style={{ color: "#323232" }}>
      <span>Pending Payments</span>
      <span className="font-bold" style={{ color: "#323232" }}>
        {data?.pendingApprovals || 0}%
      </span>
    </li>
    <li className="flex justify-between items-center" style={{ color: "#323232" }}>
      <span>Cancellations</span>
      <span className="font-bold" style={{ color: "#323232" }}>
        {data?.cancellations || 0}
      </span>
    </li>
  </ul>
</div>
</div>

  );
};

export default Dashboard;
